import l from "../Home/_locale"

export const prosjekter = [{
    internal_title: "Sprøytevogn",
    title: l("prosjekter_sprøytevogn_title"),
    image: "./Grenland rail sprøytevogn.jpg",
    description: l("prosjekter_sprøytevogn_description"),
    carouselImages: [
        { src: "./Grenland rail sprøytevogn.jpg", description: l("prosjekter_sprøytevogn_image_description_1") },
        { src: "./Grenland_2.jpg", description: l("prosjekter_sprøytevogn_image_description_2") },
        { src: "./Grenland_4.jpg", description: l("prosjekter_sprøytevogn_image_description_3") },
        { src: "./Grenland_3.jpg", description: l("prosjekter_sprøytevogn_image_description_4") },
    ],
    longerDescription: [
        l("prosjekter_sprøytevogn_description_1"),
        l("prosjekter_sprøytevogn_description_2")
    ],
}, {
    internal_title: "Seaway phoenix",
    title: l("prosjekter_seaway_phoenix_title"),
    image: "./seaway phoenix.jpg",
    description: l("prosjekter_seaway_phoenix_description"),
    carouselImages: [
        { src: "./seaway phoenix.jpg", description: l("prosjekter_seaway_phoenix_image_description_1") },
        { src: "./seaway_4.png", description: l("prosjekter_seaway_phoenix_image_description_2") },
        { src: "./seaway_3.jpg", description: l("prosjekter_seaway_phoenix_image_description_3") },
        { src: "./seaway_2.jpg", description: l("prosjekter_seaway_phoenix_image_description_4") },
        { src: "./seaway_1.jpg", description: l("prosjekter_seaway_phoenix_image_description_5") },
    ],
    longerDescription: [
        l("prosjekter_seaway_phoenix_description_1"),
        l("prosjekter_seaway_phoenix_description_2"),
        l("prosjekter_seaway_phoenix_description_3"),
    ]
}, {
    internal_title: "AASS CIP YMS",
    title: l("prosjekter_CIPYMS_AASS_title"),
    logo: "",
    image: "./AASS_CIP_YMS.png",
    description: l("prosjekter_CIPYMS_AASS_description"),
    carouselImages: [
        { src: "./AASS_CIP_YMS.png", description: "SCADA levert i Zenon" },
    ],
    longerDescription: ``,
}, {
    internal_title: "AASS CIP6",
    title: l("prosjekter_CIP6_AASS_title"),
    logo: "",
    image: "./AASS_CIP6.JPG",
    description: l("prosjekter_CIP6_AASS_description"),
    carouselImages: [
        { src: "./AASS_CIP6.JPG", description: l("prosjekter_CIP6_AASS_image_description_1") },
    ],
    longerDescription: [
        l("prosjekter_CIP6_AASS_description_1")
    ],
}, {
    internal_title: "Synnove CIP Alvdal",
    title: l("prosjekter_CIP_alvdal_title"),
    logo: "",
    image: "./synnove_1_1.png",
    description: l("prosjekter_CIP_alvdal_description"),
    carouselImages: [
        { src: "./synnove_2.PNG", description: l("prosjekter_CIP_alvdal_image_description_1") },
        { src: "./synnove_7.jpg", description: l("prosjekter_CIP_alvdal_image_description_2") },
        { src: "./synnove_8.jpeg", description: l("prosjekter_CIP_alvdal_image_description_3") },
        { src: "./synnove_9.jpg", description: l("prosjekter_CIP_alvdal_image_description_4") },
        { src: "./synnove_4.jpg", description: l("prosjekter_CIP_alvdal_image_description_5") },
        { src: "./synnove_5.jpg", description: l("prosjekter_CIP_alvdal_image_description_6") },
        { src: "./synnove_6.jpg", description: l("prosjekter_CIP_alvdal_image_description_7") },
    ],
    longerDescription: [
        l("prosjekter_CIP_alvdal_description_1"),
        l("prosjekter_CIP_alvdal_description_2"),
    ],
}, {
    internal_title: "Synnove CIP Namsos",
    title: l("prosjekter_CIP_namsos_title"),
    logo: "",
    image: "./synnove_namsos.jpg",
    description: l("prosjekter_CIP_namsos_description"),
    carouselImages: [
        { src: "./synnove_namsos.jpg", description: "" },
    ],
    longerDescription: l("prosjekter_CIP_namsos_description_1"),
}, {
    internal_title: "Nordisk Lindum",
    title: l("prosjekter_nordisk_lindum_title"),
    logo: "",
    image: "./nordisk lindum 1.jpg",
    description: l("prosjekter_nordisk_lindum_description"),
    carouselImages: [
        { src: "./nordisk lindum 1.jpg", description: "" },
    ],
    longerDescription: ``,
}, {
    internal_title: "Inntre",
    title: l("prosjekter_Inntre_title"),
    logo: "",
    image: "./inntre_1.jpg",
    description: l("prosjekter_Inntre_description"),
    carouselImages: [
        { src: "./inntre_1.jpg", description: l("prosjekter_Inntre_image_description_1") },
    ],
    longerDescription: [
        l("prosjekter_Inntre_description_1"),
    ]
}]
