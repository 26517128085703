import React from "react"
import { Carousel, Typography } from "antd"

import { referanser } from "./referanser.source"
import "./less/referanser.less"

export class Referanser extends React.Component {
  render() {
    return <>
      <div className="logoContainer">
        {referanser.map(x =>
          <img
            key={x.image + x.title}
            src={x.image}
            alt={x.title}
          />)
        }
      </div>
      <Carousel
        autoplay
        autoplaySpeed={10000}
      >
        {referanser.map((ref, i) => <div
          className="reference"
          key={"reference" + ref.title}
        >
          <img
            src={ref.image}
            alt={ref.title}
          />
          {/* <Typography.Title>{ref.title}</Typography.Title> */}
          <p>{ref.description}</p>
          <p className="navnelinje">{ref.navnelinje}</p>
        </div>)}
      </Carousel>
    </>
  }
}
