import React from "react";
import logoFisheye from "../static/FishEye Logo.png";
import imgAssetMonitoring from "../static/imgAssetMonitoring.png";
import imgDigitalChecklists from "../static/ChecklistGR.png";
import imgIntegration from "../static/Integration.png";
import imgReportBG from "../static/ReportBG.png";
import appAndSystem from "../static/appAndSystem.jpg";
import sens from "../static/fisheye-sens-2.png";
import consulting_1 from "../static/consulting_woodtech.jpg"
import locale from "./_locale"
import schlumbergerLogo from "../static/logo/schlumberger-logo-white.svg"
import peregrinoLogo from "../static/logo/peregrino.jpg"
import brynildLogo from "../static/logo/brynild.png"
import synnoveLogo from "../static/logo/synnove.png"
import mariann from "../static/mariann.png"

//     children: [{name: "Schlumberger", image: schlumbergerLogo}, {name:"Peregrino", image: peregrinoLogo}, {name: "Brynild", image: brynildLogo}, {name: "Synnøve Finden", image: synnoveLogo}, /*{name: "Nordisk Vannteknikk", image: nvtkLogo}*/].map((productName, i) => {

import {
  createFromIconfontCN,
} from "@ant-design/icons";
import { Menu } from "antd";
import { Link } from "react-router-dom";
const { Item, SubMenu } = Menu;

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js",
});

export const Banner00DataSource = {
  wrapper: { className: "banner0 kb2gcv595b-editor_css" },
  textWrapper: { className: "banner0-text-wrapper" },
  title: {
    className: "banner0-title",
    children: (
      <span>
        <span>
          <p>{locale("slagord")}</p>
        </span>
      </span>
    ),
  },
  content: {
    className: "banner0-content",
    children: (
      <span>
        <p></p>
      </span>
    ),
  },
  button: {
    className: "banner0-button",
    children: (
      <span>
        <p>
          <br />
        </p>
      </span>
    ),
  },
};
let menuItemStyle = {
  color: "white",
  fontWeight: 100,
}
export const Nav00DataSource = {
  wrapper: { className: "header0 home-page-wrapper kb2gbwgwz1i-editor_css" },
  page: { className: "home-page" },
  logo: {
    className: "header0-logo",
    children: logoFisheye,
  },
  Menu: {
    className: "header0-menu",
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: (
          <Item key="#/">
            <Link to="/">
              <h2 style={menuItemStyle}>{locale("page_header_menu_solutions")}</h2>
            </Link>
          </Item>
        ),
      },  {
        name: 'item2',
        className: 'header0-item',
        children: (
          <Item key="#/aboutus">
            <Link to="/aboutus">
              <h2 style={menuItemStyle}>{locale("page_header_menu_about_us")}</h2>
            </Link>
          </Item>
        ),
      }, /*{
        name: 'item3',
        className: 'header0-item',
        children: (
          <Item key="#/team">
            <Link to="/team">
              <h2 style={menuItemStyle}>{locale("team_header")}</h2>
            </Link>
          </Item>
        ),
      },*/
    ],
  },
  mobileMenu: { className: "header0-mobile-menu" },
};
export const Content10DataSource = {
  wrapper: {
    className: "home-page-wrapper content1-wrapper kb2gnserea6-editor_css",
  },
  OverPack: { className: "home-page content1", playScale: 0.3 },
  imgWrapper: { className: "content1-img", md: 10, xs: 24 },
  img: {
    children: "/rendered.png",
  },
  textWrapper: { className: "content1-text", md: 14, xs: 24 },
};
// Bedre innsikt/Økt vekst/Spar tid og penger
export const Content30DataSource = {
  wrapper: { className: "home-page-wrapper content3-wrapper" },
  page: { className: "home-page content3" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [{ name: "title", children: "Automatiser dine arbeidsoppgaver" }],
  },
  childWrapper: {
    className: "content3-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          className: "content3-block-item",
          children: [
            {
              name: "image",
              className: "content3-block-icon",
              children: "/innsikt.png",
            },
            {
              name: "title",
              className: "content3-block-title",
              children: locale("IIOTcard_1_header"),
            },
            {
              name: "content",
              children:
                locale("IIOTcard_1_body"),
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          className: "content3-block-item",
          children: [
            {
              name: "image",
              className: "content3-block-icon",
              children: "/increase_transparent.png",
            },
            {
              name: "title",
              className: "content3-block-title",
              children: locale("IIOTcard_2_header"),
            },
            {
              name: "content",
              children:
                locale("IIOTcard_2_body"),
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          className: "content3-block-item",
          children: [
            {
              name: "image",
              className: "content3-block-icon",
              children: "/timemoney_transparent.png",
            },
            {
              name: "title",
              className: "content3-block-title",
              children: locale("IIOTcard_3_header"),
            },
            {
              name: "content",
              children:
                locale("IIOTcard_3_body"),
            },
          ],
        },
      },
    ],
  },
};
// Ikke i brukt
export const Content00DataSource = {
  wrapper: { className: "home-page-wrapper content0-wrapper" },
  page: { className: "home-page content0" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [{ name: "title", children: "Automatiser dine arbeidsoppgaver" }],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: "/overvak.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: "Overvåk",
            },
            {
              name: "content",
              children:
                "Få full oversikt over alle dine enheter. Hent data automatisk, digitaliser sjekklister og reager på hendelser.",
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: "/integrer.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: "Integrer",
            },
            {
              name: "content",
              children:
                "Integrer plattformen med andre forretningssystemer og spar deg selv for utallige arbeidstimer. ERP / OT / Vedlikeholssystemer mm...",
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: "/visualiseranalyser.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: "Visualiser og analyser",
            },
            {
              name: "content",
              children:
                "Få full oversikt, visualiser og kontroller alle enhetene dine. Reduser kostnader, effektiviser og øk kapasitet ved å analysere data og generere rapporter.",
            },
          ],
        },
      },
    ],
  },
};
export const FeedingAutomat1Datasource = {
  wrapper: { className: "home-page-wrapper feature1-wrapper" },
  OverPack: { className: "home-page feature1", playScale: 0.3 },
  imgWrapper: { className: "feature1-img", md: 10, xs: 24 },
  img: {
    children: appAndSystem,
  },
  textWrapper: { className: "feature1-text", md: 14, xs: 24 },
  title: { className: "feature1-title", children: locale("main_page_feeding_system_feeders_title") },
  content: {
    className: "feature1-content",
    children:
      locale("main_page_feeding_system_feeders_description"),
  },
};

export const Sens1Datasource = {
  wrapper: { className: "home-page-wrapper feature1-wrapper" },
  OverPack: { className: "home-page feature1", playScale: 0.3 },
  imgWrapper: { className: "feature1-img", md: 10, xs: 24, style:{marginBottom: 40}  },
  img: {
    children: sens,
  },
  textWrapper: { className: "feature1-text", md: 14, xs: 24},
  title: { className: "feature1-title", children: locale("main_page_feeding_system_sens_sub_title") },
  content: {
    className: "feature1-content",
    children:
      locale("main_page_feeding_system_sens_sub_description"),
  },
};
export const Analytics1Datasource = {
  wrapper: { className: "home-page-wrapper feature1-wrapper" },
  OverPack: { className: "home-page feature1", playScale: 0.3 },
  imgWrapper: { className: "feature1-img", md: 10, xs: 24 },
  img: {
    children: sens,
  },
  textWrapper: { className: "feature1-text", md: 14, xs: 24 },
  title: { className: "feature1-title", children: locale("main_page_feeding_system_analytics_sub_title") },
  content: {
    className: "feature1-content",
    children:
      locale("main_page_feeding_system_analytics_sub_description"),
  },
};
export const EPCI1Datasource = {
  wrapper: { className: "home-page-wrapper feature1-wrapper" },
  OverPack: { className: "home-page feature1", playScale: 0.3 },
  imgWrapper: { className: "feature1-img", md: 10, xs: 24 },
  img: {
    children: appAndSystem,
  },
  textWrapper: { className: "feature1-text", md: 14, xs: 24 },
  title: { className: "feature1-title", children: locale("CIP_header") },
  content: {
    className: "feature1-content",
    children:
      locale("CIP_body"),
  },
};
export const Consulting2Datasource = {
  wrapper: { className: "home-page-wrapper feature2-wrapper" },
  OverPack: { className: "home-page feature2", playScale: 0.3 },
  imgWrapper: { className: "feature2-img", md: 10, xs: 24 },
  img: {
    children: consulting_1,
  },
  textWrapper: { className: "feature2-text", md: 14, xs: 24 },
  title: { className: "feature2-title", children: "" },
  content: {
    className: "feature2-content",
    children: [
      <p></p>,
      <p>{locale("consulting_body")}
      </p>
    ]
  },
};
export const Consulting3DataSource = {
  wrapper: { className: "home-page-wrapper content3-wrapper" },
  page: { className: "home-page content3" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [{ name: "title", style: {}, children: "Prosjekter" }],
  },
  childWrapper: {
    className: "content3-block-wrapper",
    children: [{ name: "Schlumberger", image: schlumbergerLogo }, { name: "Peregrino", image: peregrinoLogo }, { name: "Brynild", image: brynildLogo }, { name: "Synnøve Finden", image: synnoveLogo }, /*{name: "Nordisk Vannteknikk", image: nvtkLogo}*/].map((customer, i) => {
      return {
        name: "block" + i,
        className: "content3-block",
        md: 6,
        xs: 24,
        children: {
          className: "content3-block-item", children: <div>
            <img src={customer.logo} width="200px" alt="img" />
          </div>
        }

      }
    })
  },
};
// export const EPCI1Datasource = {
//   wrapper: { className: "home-page-wrapper feature1-wrapper" },
//   OverPack: { className: "home-page feature1", playScale: 0.3 },
//   imgWrapper: { className: "feature1-img", md: 10, xs: 24 },
//   img: {
//     children: synnove_3,
//   },
//   textWrapper: { className: "feature1-text", md: 14, xs: 24 },
//   title: { className: "feature1-title", children: "Nøkkelferdige løsninger" },
//   content: {
//     className: "feature1-content",
//     children:
//       "Vi har lang erfaring innen EPCI (Engineering, Procurement, Construction and Installation) prosjekter, prosesskontroll og produkttransport. Våre komplette tjenester inkluderer hele prosessen fra engineering til installasjon og igangsetting av anlegget.",
//   },
// };
export const Feature10DataSource = {
  wrapper: { className: "home-page-wrapper feature1-wrapper" },
  OverPack: { className: "home-page feature1", playScale: 0.3 },
  imgWrapper: { className: "feature1-img", md: 10, xs: 24 },
  img: {
    children: imgAssetMonitoring,
  },
  textWrapper: { className: "feature1-text", md: 14, xs: 24 },
  title: { className: "feature1-title", children: locale("IIOT1_header") },
  content: {
    className: "feature1-content",
    children:
      locale("IIOT1_body"),
  },
};
export const Feature20DataSource = {
  wrapper: { className: "home-page-wrapper feature2-wrapper" },
  OverPack: { className: "home-page feature2", playScale: 0.3 },
  imgWrapper: { className: "feature2-img", md: 10, xs: 24 },
  img: {
    children: imgDigitalChecklists,
  },
  textWrapper: { className: "feature2-text", md: 14, xs: 24 },
  title: { className: "feature2-title", children: locale("IIOT2_header") },
  content: {
    className: "feature2-content",
    children:
      locale("IIOT2_body"),
  },
};
export const Feature30DataSource = {
  wrapper: { className: "home-page-wrapper feature3-wrapper" },
  OverPack: { className: "home-page feature3", playScale: 0.3 },
  imgWrapper: { className: "feature3-img", md: 10, xs: 24 },
  img: {
    children: imgIntegration,
  },
  textWrapper: { className: "feature3-text", md: 14, xs: 24 },
  title: { className: "feature3-title", children: locale("IIOT3_header") },
  content: {
    className: "feature3-content",
    children:
      locale("IIOT3_body"),
  },
};
export const Feature40DataSource = {
  wrapper: { className: "home-page-wrapper feature4-wrapper" },
  OverPack: { className: "home-page feature4", playScale: 0.3 },
  imgWrapper: { className: "feature4-img", md: 10, xs: 24 },
  img: {
    children: imgReportBG,
  },
  textWrapper: { className: "feature4-text", md: 14, xs: 24 },
  title: { className: "feature4-title", children: locale("IIOT4_header") },
  content: {
    className: "feature4-content",
    children:
      locale("IIOT4_body"),
  },
};
export const Content20DataSource = {
  wrapper: {
    className: "home-page-wrapper content2-wrapper kb2gnserea6-editor_css",
  },
  OverPack: { className: "home-page content2", playScale: 0.3 },
  imgWrapper: { className: "content2-img", md: 10, xs: 24 },
  img: {
    children: "/GOLD-App-Cloud-SILVER-Business-1.png",
  },
  textWrapper: { className: "content2-text", md: 14, xs: 24 },
  title: { className: "content2-title", children: "Microsoft partner" },
  content: {
    className: "content2-content",
    children:
      "Vår plattform er bygget på Microsoft tjenester og produkter som gir de mest moderne og markedsledende løsningene for bedriftsapplikasjoner.",
  },
  content2: {
    className: "content2-content",
    children:
      "Vi er en Microsoft partner med sertifisert kompetanse innen apputvikling, IoT løsninger, data analyse og rapportering.",
  },
  content3: {
    className: "content2-content",
    children: "",
  },
};
export const Teams10DataSource = {
  wrapper: { className: "home-page-wrapper teams1-wrapper" },
  page: { className: "home-page teams1" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [{ name: "title", children: locale("contact_header") }],
  },
  block: {
    className: "block-wrapper",
    children: [
      {
        name: "block0",
        className: "block",
        md: 24,
        xs: 24,
        titleWrapper: {
          children: [
            {
              key: "profile image",
              name: "image",
              className: "teams1-image",
              children: "/alex.png",
            },
            {
              key: "title",
              name: "title",
              className: "teams1-title",
              children: "Ole Alexander Hagen Bakke",
            },
            {
              key: "content",
              name: "content",
              className: "teams1-job",
              children: locale("team_member_oab_title"),
            },
            {
              key: "content1",
              name: "content1",
              className: "teams1-content",
              children: (
                <div>
                  <a style={{ color: "#919191" }} href="tel:+4790258045">
                    +47 902 58 045
                  </a>{" "}
                  |{" "}
                  <a style={{ color: "#919191" }} href="mailto:oab@d-eng.no">
                    oab@d-eng.no
                  </a>
                </div>
              ),
            },
          ],
        },
      },
      /*{
        name: "block1",
        className: "block",
        md: 12,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: "image",
              className: "teams1-image",
              children: mariann,
            },
            {
              name: "title",
              className: "teams1-title",
              children: "Mariann Rossow Sethne",
            },
            {
              name: "content",
              className: "teams1-job",
              children: locale("team_member_mrs_title"),
            },
            {
              name: "content1",
              className: "teams1-content",
              children: (
                <div>
                  <a style={{ color: "#919191" }} href="tel:+4797007811">
                    +47 97 00 78 11
                  </a>{" "}
                  |{" "}
                  <a style={{ color: "#919191" }} href="mailto:mrs@d-eng.no">
                    mrs@d-eng.no
                  </a>
                </div>
              ),
            },
          ],
        },
      },*/
    ],
  },
};
export const Contact00DataSource = {
  wrapper: { className: "home-page-wrapper content10-wrapper" },
  Content: {
    className: "icon-wrapper",
    children: {
      icon: {
        className: "icon",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg",
        name: "icon",
      },
      iconShadow: {
        className: "icon-shadow",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg",
        name: "icon-shadow",
      },
      url: {
        children:
          "https://www.google.com/maps/place/St%C3%B8perigata+7a,+3040+Drammen/@59.7271088,10.2472267,17z/data=!3m1!4b1!4m5!3m4!1s0x46413c9b25de52e9:0xeb44fcf80de26de5!8m2!3d59.7271088!4d10.2494154",
        name: "Link",
      },
      title: { children: "Dynamic Engineering AS", name: "Navn" },
      content: {
        children: "Støperigata 7a, 3040 Drammen",
        name: "Addresse",
      },
    },
  },
};
export const Footer20DataSource = {
  wrapper: { className: "home-page-wrapper footer2-wrapper" },
  OverPack: { className: "home-page footer2", playScale: 0.05 },
  copyright: {
    className: "copyright",
    children: [
      {
        name: "image",
        children: "/DE_Logo-PNG_White_spinner.png",
        className: "copyright-logo",
      },
      {
        name: "group",
        children: "Dynamic Engineering AS",
        className: "copyright-group",
      },
      {
        name: "image2",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/fgGmQUfiUfSBfvsQpfOj.svg",
        className: "copyright-line",
      },
      {
        name: "copyright",
        children: "Copyright ©",
        className: "copyright-text",
      },
    ],
  },
  links: {
    className: "links",
    children: [],
  },
};
