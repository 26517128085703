import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import locale from "./_locale"

// images
import consulting from '../static/consulting.png'
import synnove from "../static/synnove_1.png"
import iiot from "../static/IIOT.png"

let boxesStyle = {
  float: "right",
  position: "static"
}

function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        {/* <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          <span {...dataSource.img}>
            <img src={dataSource.img.children} width="100%" alt="img" />
          </span>
        </TweenOne> */}
        {[{ img: synnove, text: locale("epci") }, { img: consulting, text: locale("consulting") }, { img: iiot, text: locale("IIOTheader") }].map(x => <QueueAnim
          key={x.text}
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: 8,
            xs: 24,
          }}
        >
          <div style={boxesStyle}>
            <h2 style={{ position: "relative", top:"50px", width: "100%", display: "inline-block", color: "#c5c5c5"}}>{x.text}</h2>
            {/* <h2 style={{ position: "relative", top:"50px", left: "50%", display: "inline-block", transform: "translateX(-50%)", color: "#c5c5c5"}}>{x.text}</h2> */}
            <img src={x.img} height="400px" width="327px" alt="img" />
          </div>
        </QueueAnim>)}
      </OverPack>
    </div>
  );
}

export default Content1;
