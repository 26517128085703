import React from "react"
import { enquireScreen } from 'enquire-js';

import {
  Teams10DataSource
} from "../Home/data.source"
import Teams1 from './Teams1';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port,
    };
  }
  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 5);
    }
  }
  render() {
    return <Teams1
      id="Teams1_0"
      key="Teams1_0"
      dataSource={Teams10DataSource}
      isMobile={this.state.isMobile}
    />
  }
}
