import { Card, Typography, Carousel } from "antd"
import React from "react"
import { withRouter } from "react-router-dom"

import "./less/projectpage.less"

import { prosjekter } from "../Prosjekter/prosjekter.source"
import { ArrowLeftOutlined } from "@ant-design/icons"

class ProjectPage extends React.Component {
  render() {
    console.log(this.props.match.params)
    let prosjekt = prosjekter.find(x => x.internal_title === this.props.match.params.internal_title)
    return <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Typography.Title style={{
        textAlign: "center"
      }}>
        {prosjekt.title}
      </Typography.Title>
      <Carousel
        autoplay
        autoplaySpeed={10000}
      >
        {prosjekt.carouselImages.map(image =>
          <Card
            bordered={false}
            cover={<img
              alt={image.description}
              src={image.src}
              style={{
                maxHeight: "400px",
                // width: "auto",
                objectFit: image.objectFit || "contain",
              }}
            />}
          >
            <Card.Meta description={image.description} />
          </Card>
        )}
      </Carousel>
      <div id="bodyContainer">
        <div className="bodytext">
          {(Array.isArray(prosjekt.longerDescription) ? prosjekt.longerDescription : [prosjekt.longerDescription]).map(x => <p>{x}</p>)}
        </div>
      </div>
    </>
  }
}

export default withRouter(ProjectPage)
