import React from "react";

import synnove_3 from "../static/synnove_3.PNG"
import l from "./../Home/_locale"
import mariann from "../static/mariann.png"

export const OmOssDatasource1 = {
  wrapper: { className: "home-page-wrapper feature1-wrapper" },
  OverPack: { className: "home-page feature1", playScale: 0.3 },
  imgWrapper: { className: "feature1-img", md: 10, xs: 24 },
  img: {
    children: synnove_3,
  },
  textWrapper: { className: "feature1-text", md: 14, xs: 24 },
  title: { className: "feature1-title", children: l("about_header_1") },
  content: {
    className: "feature1-content",
    children:
      l("about_text_1"),
  },
};
const teamMembers = [
  {
    name: "Ole Alexander Hagen Bakke",
    image: "/alex.png",
    title: l("team_member_oab_title"),
    email: "oab@d-eng.no",
    phone: "+47 902 58 045",
  }, /*{
    name: "Mariann Rossow Sethne",
    image: mariann,
    title: l("team_member_mrs_title"),
    email: "mrs@d-eng.no",
    phone: "+47 4007 1009",
  }, */{
    name: "Daniel Vestøl",
    image: "",
    title: l("team_member_dvl_title"),
    email: "dvl@d-eng.no",
    phone: "+47 960 42 128",
  }, {
    name: "Bartosz Polanski",
    image: "",
    title: l("team_member_bpi_title"),
    email: "bpi@d-eng.no",
    phone: "+47 454 05 021",
  }, {
    name: "Robin Rene Risøy Jönsson",
    image: "",
    title: l("team_member_rrj_title"),
    email: "rrj@d-eng.no",
    phone: "+47 484 91 650",
  }, {
    name: "Eivind Ulsten",
    image: "",
    title: l("team_member_eun_title"),
    email: "eun@d-eng.no",
    phone: "+47 413 93 580",
  }, {
    name: "Thomas Bryksin",
    image: "",
    title: l("team_member_tbn_title"),
    email: "tbn@d-eng.no",
    phone: "+47 948 90 189",
  }, {
    name: "Erik Angelsen",
    image: "",
    title: l("team_member_ean_title"),
    email: "ean@d-eng.no",
    phone: "+47 4810 4810",
  }, {
    name: "Magnus Hansen",
    image: "",
    title: l("team_member_mhn_title"),
    email: "mhn@d-eng.no",
    phone: "+47 900 32 341",
  }, {
    name: "Xuan An Olav Nguyen",
    image: "",
    title: l("team_member_xon_title"),
    email: "xon@d-eng.no",
    phone: "",
  },
]
// Listing with team members, photos and contact information
export const TeamsDatasource1 = {
  wrapper: { className: "home-page-wrapper teams1-wrapper" },
  page: { className: "home-page teams1" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [{ name: "title", children: l("team_header") }],
  },
  block: {
    className: "block-wrapper",
    children: teamMembers.map(member => {
      return {
        key: member.name,
        name: "block0",
        className: "block",
        md: 12,
        xs: 24,
        titleWrapper: {
          children: [
            {
              key: "profile image",
              name: "image",
              className: "teams1-image",
              children: member.image,
            },
            {
              key: "title",
              name: "title",
              className: "teams1-title",
              children: member.name,
            },
            {
              key: "content",
              name: "content",
              className: "teams1-job",
              children: member.title,
            },
            {
              key: "content1",
              name: "content1",
              className: "teams1-content",
              children: (
                <div>
                  <a style={{ color: "#919191" }} href={`tel:${member.phone.split(" ").join()}`}>
                    {member.phone}
                  </a>{" "}
                  |{" "}
                  <a style={{ color: "#919191" }} href={`mailto:${member.email}`}>
                    {member.email}
                  </a>
                </div>
              ),
            },
          ],
        },
      }
    })
  },
};