// Import locale files
import no from "./../locale/no/locale.json"
import en from "./../locale/en/locale.json"

let debug = false;
export default function (key) {
    if (debug) {
        return "<" + key + ">"
    } else {
        if (locale[localStorage.locale || "no"][key] === false || locale[localStorage.locale || "no"][key] === undefined || locale[localStorage.locale || "no"][key] === "") {
            // Look for string in alternate languages
            for (let identifier in locale) {
                let translation = locale[identifier]
                if (![undefined, false, ""].includes(translation[key])) return translation[key]
            }
            if(localStorage.developer) return "<" + key + ">"
            return ""
        } else return locale[localStorage.locale || "no"][key]
    }
}

let locale = {
    en,
    no,
}
