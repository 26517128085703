import l from "../Home/_locale"

export const referanser = [{
    internal_title: "Synnøve Finden",
    title: l("referanse_synnove_title"),
    image: "./logo/synnove-logo-plain.png",
    description: l("referanse_synnove_description"),
    navnelinje: l("referanse_synnove_navnelinje"),
}, {
    internal_title: "Brynild",
    title: l("referanse_brynild_title"),
    image: "./logo/brynild_logo.png",
    description: l("referanse_brynild_description"),
    navnelinje: l("referanse_brynild_navnelinje"),
}, {
    internal_title: "aass",
    title: l("referanse_aass_title"),
    image: "./logo/aass-logo-neb.png",
    description: l("referanse_aass_description"),
    navnelinje: l("referanse_aass_navnelinje"),
},]
