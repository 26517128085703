import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { isImg } from '../_general/utils';

import { CopyrightOutlined } from '@ant-design/icons'

class Footer2 extends React.PureComponent {
  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    return (
      <div {...props} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <TweenOne {...dataSource.links}>
            {dataSource.links.children.map((item, i) => {
              return (
                <a key={i.toString()} {...item}>
                  {item.children}
                </a>
              );
            })}
          </TweenOne>
          <TweenOne
            animation={{ x: '-=30', opacity: 0, type: 'from' }}
            key="copyright"
            style={{
              float:"left"
            }}
          >
            <p style={{lineHeight : "50px"}}><CopyrightOutlined /> Copyright Dynamic Engineering 2021</p>
            <p style={{lineHeight : "0px"}}>Org nr: 919 357 444</p>
          </TweenOne>
          <TweenOne
            animation={{ x: '+=30', opacity: 0, type: 'from' }}
            key="logo"
            {...dataSource.copyright}
            style={{
              float:"right"
            }}
          >
            {/* {dataSource.copyright.children.map((item, i) =>
              React.createElement(
                item.name.indexOf('title') === 0 ? 'h1' : 'div',
                { key: i.toString(), ...item },
                typeof item.children === 'string' && item.children.match(isImg)
                  ? React.createElement('img', {
                      src: item.children,
                      alt: 'img',
                    })
                  : item.children
              )
            )} */}
            <img src='/DE_Logo-PNG_White_spinner.png' style={{height: "40px"}}></img>
            <div>Dynamic Engineering AS</div>
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Footer2;
