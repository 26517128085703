import synnove_3 from "../static/synnove_3.PNG"
import feedingsystem_1 from "../static/feedingsystem_1.png"
import dynamic_iiot_1 from "../static/dynamic_iiot_1.png"
import feedingsystem_2 from "../static/feedingsystem_2.png"
import locale from "./../Home/_locale"
import consulting_1 from "../static/consulting_woodtech.jpg"

export const OmOssDatasource1 = {
  wrapper: { className: "home-page-wrapper feature1-wrapper" },
  OverPack: { className: "home-page feature1", playScale: 0.3 },
  imgWrapper: { className: "feature1-img", md: 10, xs: 24 },
  img: {
    children: dynamic_iiot_1,
  },
  textWrapper: { className: "feature1-text", md: 14, xs: 24 },
  title: { className: "feature1-title", children: locale("about_header_1") },
  content: {
    className: "feature1-content",
    children:
      locale("about_text_1"),
  },
};
export const OmOssDatasource2 = {
  wrapper: { className: "home-page-wrapper feature2-wrapper" },
  OverPack: { className: "home-page feature2", playScale: 0.3 },
  imgWrapper: { className: "feature2-img", md: 10, xs: 24 },
  img: {
    children: consulting_1,
  },
  textWrapper: { className: "feature2-text", md: 14, xs: 24 },
  title: { className: "feature2-title", children: locale("about_header_2") },
  content: {
    className: "feature2-content",
    children:
      locale("about_text_2"),
  },
};
export const OmOssDatasource3 = {
  wrapper: { className: "home-page-wrapper feature1-wrapper" },
  OverPack: { className: "home-page feature1", playScale: 0.3 },
  imgWrapper: { className: "feature1-img", md: 10, xs: 24 },
  img: {
    children: feedingsystem_2,
  },
  textWrapper: { className: "feature1-text", md: 14, xs: 24 },
  title: { className: "feature1-title", children: locale("about_header_3") },
  content: {
    className: "feature1-content",
    children:
      locale("about_text_3"),
  },
};
