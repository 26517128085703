import React from "react";

export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/;
function endsWithImage(string) {
  if (string.includes(".png") || string.includes(".jpg")) return true;
}
export const getChildrenToRender = (item, i) => {
  let tag = item.name && item.name.indexOf("title") === 0 ? "h1" : "div";
  tag = item.href ? "a" : tag;
  let children =
    typeof item.children === "string" && endsWithImage(item.children)
      ? React.createElement("img", { src: item.children, alt: "img", key: Math.random().toString() })
      : item.children;
  // if (item.name.indexOf('button') === 0 && typeof item.children === 'object') {
  //   children = React.createElement(Button, {
  //     ...item.children
  //   });
  // }
  // console.log(item.name, item.href, children);
  return typeof children === "object"
    ? children
    : React.createElement(
      tag,
      { key: Math.random().toString(), ...item },
      children
    );
};
