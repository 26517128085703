/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import { Card, Typography } from 'antd';

import Feature1 from './../Home/Feature1';
import Feature2 from './../Home/Feature2';

import l from "../Home/_locale"
import {
    OmOssDatasource1,
    OmOssDatasource2,
    OmOssDatasource3,
} from "./omoss.source"

import "./less/omoss.less"
import Contact from '../_general/contact';

const { location = {} } = typeof window !== 'undefined' ? window : {};
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

export default class Prosjekter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port,
        };
    }

    componentDidMount() {
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        if (location.port) {
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 5);
        }
    }
    render() {
        return (
            <div>
                <div style={{
                    height: "100px"
                }}></div>
                {/* <Typography.Title style={{
                    textAlign: "center"
                }}>
                    {l("about_header")}
                </Typography.Title> */}
                <div className="aboutUsContainer">
                </div>
                <Feature1
                    id="EPCIFeature1_0"
                    key="EPCIFeature1_0"
                    dataSource={OmOssDatasource1}
                    isMobile={this.state.isMobile}
                />,
                <Feature2
                    id="ConsultingFeature2_0"
                    key="ConsultingFeature2_111"
                    dataSource={OmOssDatasource2}
                    isMobile={this.state.isMobile}
                />,
                <Feature1
                    id="EPCIFeature1_1"
                    key="EPCIFeature1_1"
                    dataSource={OmOssDatasource3}
                    isMobile={this.state.isMobile}
                />,
                <Contact />
            </div>
        )
    }
}
