/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Teams1 from "./../_general/Teams1";

import {
    TeamsDatasource1,
} from "./team.source"

import "./less/team.less"

const { location = {} } = typeof window !== 'undefined' ? window : {};
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

export default class Prosjekter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port,
        };
    }

    componentDidMount() {
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        if (location.port) {
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 5);
        }
    }
    render() {
        return (
            <div>
                <div style={{
                    height: "100px"
                }}></div>
                <Teams1
                    id="Teams2_0"
                    key="Teams2_0"
                    dataSource={TeamsDatasource1}
                    isMobile={this.state.isMobile}
                />
            </div>
        )
    }
}
