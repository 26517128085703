import React from "react";
import TweenOne from "rc-tween-one";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons"
import { getChildrenToRender } from "../_general/utils";

import l from "../Home/_locale"

const { Item, SubMenu } = Menu;

const menu = (
  <Menu>
    <Menu.Item>
      <a target="_blank" onClick={e => {
        localStorage.locale = "no"
        window.gtag('event', 'change_language', {"locale": localStorage.locale});
        window.location.reload()
      }}>
        Norsk
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" onClick={e => {
        localStorage.locale = "en"
        window.gtag('event', 'change_language', {"locale": localStorage.locale});
        window.location.reload()
      }}>
        English
      </a>
    </Menu.Item>
  </Menu>
)

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: undefined,
    };
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };

  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const { phoneOpen } = this.state;
    const navData = dataSource.Menu.children;
    const navChildren = navData.map((item) => {
      const { children: a, subItem, ...itemProps } = item;
      return item.children;
    });
    const moment = phoneOpen === undefined ? 300 : null;
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: "from" }}
        {...dataSource.wrapper}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? " open" : ""}`}
        >
          <TweenOne
            style={isMobile ? { float: "left", marginRight: 20 } : { float: "left", marginRight: 70, }}
            animation={{ x: -30, type: "from", ease: "easeOutQuad" }}
            {...dataSource.logo}
          >
            {isMobile ?
              <img width="100px" src={dataSource.logo.children} alt="img" />
              :
              <img width="200px" src={dataSource.logo.children} alt="img" />
            }
          </TweenOne>
          {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick();
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          <TweenOne
            {...dataSource.Menu}
            animation={
              isMobile
                ? {
                  height: 0,
                  duration: 300,
                  onComplete: (e) => {
                    if (this.state.phoneOpen) {
                      e.target.style.height = "auto";
                    }
                  },
                  ease: "easeInOutQuad",
                }
                : null
            }
            moment={moment}
            reverse={!!phoneOpen}
          >
            <Menu
              mode={isMobile ? "inline" : "horizontal"}
              selectedKeys={[document.location.hash]}
              theme="dark"
              onClick={({key}) => window.gtag('event', 'menu_click', {key, "locale": localStorage.locale})}
            >
              {navChildren}
            </Menu>
          </TweenOne>
        </div><div
          style={{
            position: "absolute",
            top: "5px",
            right: "10px",
          }}
        >
          <Dropdown overlay={menu}
          >
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              Language <DownOutlined />
            </a>
          </Dropdown>
        </div>
      </TweenOne>
    );
  }
}

export default Header;
