/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import { Card, Typography } from 'antd';
import { Link } from "react-router-dom";
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

import { prosjekter } from "./prosjekter.source"
import l from "../Home/_locale"

import "./less/projects.less"

const { location = {} } = typeof window !== 'undefined' ? window : {};
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

export default class Prosjekter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port,
    };
  }

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 5);
    }
  }
  render() {
    const animType = {
      queue: this.state.isMobile ? 'bottom' : 'left',
      one: this.state.isMobile
        ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
        : {
          x: '+=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
    };
    return (
      <div>
        <div style={{
          height: "100px"
        }}></div>
        <Typography.Title style={{
          textAlign: "center"
        }}>
          {l("prosjekter_header")}
        </Typography.Title>
        {/* <div className="projectContainer"> */}
        <OverPack componentProps={{ className: "projectContainer" }}>
          {prosjekter.map((prosjekt, i) => {
            return <TweenOne
              key={`project-${i}`}
              animation={{ opacity: 1 }}
              className="code-box-shape"
              style={{ opacity: 0, marginBottom: 10 }}
              leaveReverse
              ease={['easeOutCubic', 'easeInCubic']}
            >
              <Link to={`/prosjekter/${prosjekt.internal_title}`}>
                <Card
                  hoverable
                  onClick={e => {
                    // Log action in google analytics
                    gtag('event', 'view_project', { 'project': prosjekt.internal_title, "locale": localStorage.locale });
                    console.log("Viewed project", prosjekt.internal_title)
                  }}
                  // title={prosjekt.title}
                  cover={<img alt="hero" src={prosjekt.image} />}
                >
                  <Card.Meta
                    title={prosjekt.title}
                    description={prosjekt.description}
                  />
                </Card>
              </Link>
            </TweenOne>
          })}
        </OverPack>
      </div>
      // </div>
    )
  }
}
